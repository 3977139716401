<template>
  <!--begin::Node-->
  <!-- <div :class="widgetClasses" class="card bg-dark text-muted"> -->
  <div class="card bg-dark text-muted">
    <!--begin::Header-->
    <div class="card-header border-0 pt-4 mt-4">
      <img
        alt="cwChain Logo"
        src="@/assets/media/logos/cw_chain.png"
        class="h-60px logo"
      />
      <!--begin::Status Badge-->
      <div class="align-content-center ">
        <div class="card-toolbar" v-if="status">
          <span class="badge rounded-pill badge-success text-dark">Online</span>
        </div>
        <div class="card-toolbar" v-else>
          <span class="badge rounded-pill badge-danger text-dark">Offline</span>
        </div>
      </div>
      <!--end::Status Badge-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="col">
        <div class="row">
          <div class="col justify-content-start">
            Sync Progress
          </div>
          <div class="col-6 justify-content-end">
            <!--begin::Progess Bar-->
            <div class="progress" style="height: 25px;">
              <div
                class="progress-bar progress-bar-striped text-dark fw-bolder fs-2"
                role="progressbar"
                :style="{ width: `${elapsed}%` }"
                :aria-valuenow="`${elapsed}`"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ Math.round(elapsed) + "%" }}
              </div>
            </div>
            <!--end::Progess Bar-->
          </div>
        </div>
        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->

        <div class="row">
          <div class="col">
            Current Sync Status
          </div>
          <div class="col">
            <div class="" v-if="pendingBlocks > 0">
              <span class="badge bg-warning text-dark">Pending</span>
            </div>
            <div class="" v-else-if="pendingBlocks == 0">
              <span class="badge bg-danger">Offline</span>
            </div>
            <div class="" v-else>
              <span class="badge bg-info text-dark">Info not available</span>
            </div>
          </div>
        </div>

        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->
        <div class="row">
          <div class="col">
            Current Block Chain Height
          </div>
          <div class="col fw-bolder text-light">{{ total }}</div>
        </div>

        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator -->

        <!-- <div class="row">
          <div class="col">
            Registered Blocks
          </div>
          <div class="col fw-bolder text-light">
            {{ syncedBlocks }}
          </div>
        </div> -->

        <!--start::Separator-->
        <!-- <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div> -->
        <!--end::Separator-->

        <div class="row">
          <div class="col">
            Pending Blocks
          </div>
          <div class="col fw-bolder text-light">
            {{ pendingBlocks }}
          </div>
        </div>

        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->

        <div
          class="row justify-content-center text-light fw-bolder fs-2 pt-5 mt-5"
        >
          Chain Info
        </div>

        <!--start::Separator-->
        <div class="row justify-content-start">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->

        <div class="row">
          <div class="col">
            Last Block ID
          </div>
          <div class="col text-muted fw-bolder">
            {{ pad(blockID, 9) }}
          </div>
        </div>

        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->

        <div class="row">
          <div class="col">
            Last Block Hash
          </div>
          <div class="col text-muted fw-bolder">
            {{ checksum }}
          </div>
        </div>

        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->

        <div class="row">
          <div class="col">
            Last Block Digital Signature
          </div>
          <div class="col" v-if="fileHash != null">
            {{ fileHash }}
          </div>
          <div class="col text-muted fw-bolder" v-else-if="fileHash == null">
            Waiting for sync...
          </div>
          <div class="col text-white fw-bolder" v-else>
            Info not available
          </div>
        </div>

        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->

        <div class="row">
          <div class="col">
            Last Synced Block Digital Signature
          </div>
          <div
            class="col fw-bolder text-muted"
            v-if="lastSyncedBlockDigitalSignature != ''"
          >
            {{ lastSyncedBlockDigitalSignature }}
          </div>
          <div class="col fw-bolder text-muted" v-else>
            Info currently not available
          </div>
        </div>

        <!--start::Separator-->
        <div class="row">
          <div class="separator mx-1 my-4"></div>
        </div>
        <!--end::Separator-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Node-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "syncStatus",
  props: {
    serviceStatus: Object,
    blockData: Object,
    status: Boolean,
    widgetClasses: String
  },
  computed: {
    total() {
      return this.serviceStatus?.totalBlocks;
    },
    syncedBlocks() {
      return this.serviceStatus?.syncedBlocks;
    },
    pendingBlocks() {
      return this.serviceStatus?.pendingBlocks;
    },
    blockID() {
      return this.blockData?.recentBlock.id;
    },
    checksum() {
      return this.blockData?.recentBlock.checksum;
    },
    fileHash() {
      return this.blockData?.recentBlock.file_hash;
    },
    previousHash() {
      return this.blockData?.recentBlock.previous_hash;
    },
    lastSyncedBlockDigitalSignature() {
      return this.blockData?.lastRegHash;
    },
    elapsed() {
      const DAY = 86400000;
      // date in local timezone
      const dateNow = new Date();
      // UTC coming midnight
      dateNow.setUTCHours(24, 0, 0, 0);
      // UTC coming midnight to msec
      const midnight =
        dateNow.getTime() + dateNow.getTimezoneOffset() * 60 * 1000;
      const utcMidnight = Number(new Date(midnight));

      //      = number of milliseconds between current UTC time and midnight of January 1, 1970
      const tmLoc = new Date();
      //The offset is in minutes -- convert it to ms
      const currTime = tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;

      const diff = utcMidnight - currTime;
      const progress = (diff / DAY) * 100;
      const progressPerCent = Number(
        (Math.round(progress * 100) / 100).toFixed(2)
      );
      const res = 100 - progressPerCent;

      return res;
    }
  },
  methods: {
    /**
     * Calculates remaining time for the chain sync
     * @returns string
     */
    calcNextSync() {
      const currTimeUTC = Number(new Date(this.getCurrentTimeUTC()));

      const midnightUTC = this.getUTCMidnight();

      const diff = Number(midnightUTC - currTimeUTC);

      return this.msToTime(diff);
    },

    /**
     * Gets the UTC midgnitime in msec
     * @returns number
     */
    getUTCMidnight() {
      // date in local timezone
      const dateNow = new Date();
      // UTC coming midnight
      dateNow.setUTCHours(24, 0, 0, 0);
      // UTC coming midnight to msec
      const midnight =
        dateNow.getTime() + dateNow.getTimezoneOffset() * 60 * 1000;
      return Number(new Date(midnight));
    },

    /**
     * Gets the UTC time
     * @returns string
     */
    getCurrentTimeUTC() {
      //RETURN:
      //      = number of milliseconds between current UTC time and midnight of January 1, 1970
      const tmLoc = new Date();
      //The offset is in minutes -- convert it to ms
      return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
    },

    /**
     * Formats miliseconds to hh:mm:ss
     * @returns string
     */
    msToTime(s) {
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z?) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }

      const ms = s % 1000;
      s = (s - ms) / 1000;
      const secs = s % 60;
      s = (s - secs) / 60;
      const mins = s % 60;
      const hrs = (s - mins) / 60;

      return pad(hrs) + " hours and " + pad(mins) + " minutes";
    },

    /**
     * Formats number with leading hashtag and zeros
     * @returns string
     */
    pad(num, size?) {
      //fixed size
      const SIZE = size ? size : 9;
      // num = num.toString();
      num = `${num}`;

      while (num.length < SIZE) {
        num = "0" + num;
      }

      return "#" + num;
    }
  }
});
</script>
