import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import SyncStatus from "./SyncStatus.vue";
import NodeInfo from "./NodeInfo.vue";
export default defineComponent({
    name: "status",
    components: {
        NodeInfo: NodeInfo,
        SyncStatus: SyncStatus
    },
    data: function () {
        return {
            serviceStatus: {
                pendingBlocks: 0,
                syncedBlocks: 0
            },
            blockData: {
                recentBlock: {},
                lastRegHash: ""
            }
        };
    },
    setup: function () {
        var store = useStore();
        store.dispatch("fetchInsights");
        store.dispatch("fetchPageData", 1);
        store.dispatch("fetchServiceStatus");
        if (store.getters.latestBlocks.length === 0) {
            store.dispatch("fetchInsights");
        }
        onMounted(function () {
            setCurrentPageTitle("Status");
        });
    },
    created: function () {
        var _this = this;
        var store = useStore();
        var delay = 500;
        setTimeout(function () {
            _this.serviceStatus = store.getters.serviceStatusInfo;
            var pendingBlocks = store.getters.pendingBlocks;
            _this.serviceStatus.pendingBlocks = pendingBlocks;
            var insights = store.getters.latestBlocks;
            var pagedBlocks = store.getters.allBlocksData;
            var mostRecentBlock = pagedBlocks.data[0];
            _this.blockData.recentBlock = mostRecentBlock;
            // last registed item
            var registeredItemFileHash = "";
            for (var i in insights) {
                var item = insights[i];
                if (item.file_hash != null) {
                    registeredItemFileHash = item.file_hash;
                    break;
                }
            }
            _this.blockData.lastRegHash = registeredItemFileHash;
        }, delay);
    },
    computed: {
        status: function () {
            var store = useStore();
            return store.getters.isOnline;
        }
    }
});
