<template>
  <!--begin::List Widget 5-->
  <div class="card bg-dark text-muted" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header justify-content-center = border-0 mt-4">
      <img
        alt="cwChain Logo"
        src="@/assets/media/logos/cw_chain.png"
        class="h-50px mt-1 logo"
      />
    </div>
    <!--end::Header-->
    <!--start::Separator-->
    <div class="menu-item">
      <div class="menu-content">
        <div class="separator mx-1 my-1"></div>
      </div>
    </div>
    <!--end::Separator-->
    <!--begin::Body-->
    <div class="card-body pt-5">
      <!--begin::Text-->
      <div class="fw-mormal timeline-content text-muted ps-3">
        <h3 class="card-title align-items-start flex-column">
          <p class="fw-bolder mb-2 text-dark">About chain sync</p>
          <p class="text-muted fw-bold fs-7">How it really works</p>
        </h3>
        <p>
          On our blockchain-based platform, every asset submitted for copyright
          takes one block which gets linked to its previous one. Therefore,
          every time a new block is added, all assets become more secure. The
          newly submitted blocks create a chain-link. Every 24 hours we take the
          chain-link created and we immutably append it to the end of our
          blockchain. We call that a chain sync.
        </p>
      </div>
      <!--end::Text-->
      <!--start::Separator-->
      <div class="menu-item">
        <div class="menu-content">
          <div class="separator mx-1 my-4"></div>
        </div>
      </div>
      <!--end::Separator-->
      <!--begin::Info section-->
      <div class="d-flex flex-wrap my-2 justify-content-center">
        <!--begin::Due-->
        <div
          class="border border-light rounded-pill min-w-125px py-4 px-4 me-7 mb-2"
        >
          <div class="fs-6 text-gray-400 fw-bold pb-2">
            Next sync in
            <span class="fs-5 text-light fw-bolder">
              {{ this.calcNextSync() }}
            </span>
          </div>
          <!-- <div class="fw-bold text-gray-400">Next Sync</div> -->
        </div>
        <!--end::Due-->
      </div>
      <!--end::Info section-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: List Widget 5-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "nodeInfo",
  props: {
    widgetClasses: String
  },
  methods: {
    /**
     * Calculates remaining time for the chain sync
     * @returns string
     */
    calcNextSync() {
      const currTimeUTC = Number(new Date(this.getCurrentTimeUTC()));

      const midnightUTC = this.getUTCMidnight();

      const diff = Number(midnightUTC - currTimeUTC);

      return this.msToTime(diff);
    },

    /**
     * Gets the UTC midgnitime in msec
     * @returns number
     */
    getUTCMidnight() {
      // date in local timezone
      const dateNow = new Date();
      // UTC coming midnight
      dateNow.setUTCHours(24, 0, 0, 0);
      // UTC coming midnight to msec
      const midnight =
        dateNow.getTime() + dateNow.getTimezoneOffset() * 60 * 1000;
      return Number(new Date(midnight));
    },

    /**
     * Gets the UTC time
     * @returns string
     */
    getCurrentTimeUTC() {
      //RETURN:
      //      = number of milliseconds between current UTC time and midnight of January 1, 1970
      const tmLoc = new Date();
      //The offset is in minutes -- convert it to ms
      return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
    },

    /**
     * Formats miliseconds to hh:mm:ss
     * @returns string
     */
    msToTime(s) {
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z?) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }

      const ms = s % 1000;
      s = (s - ms) / 1000;
      const secs = s % 60;
      s = (s - secs) / 60;
      const mins = s % 60;
      const hrs = (s - mins) / 60;

      return pad(hrs) + " hours & " + pad(mins) + " minutes";
    }
  }
});
</script>
