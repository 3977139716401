import { defineComponent } from "vue";
export default defineComponent({
    name: "syncStatus",
    props: {
        serviceStatus: Object,
        blockData: Object,
        status: Boolean,
        widgetClasses: String
    },
    computed: {
        total: function () {
            var _a;
            return (_a = this.serviceStatus) === null || _a === void 0 ? void 0 : _a.totalBlocks;
        },
        syncedBlocks: function () {
            var _a;
            return (_a = this.serviceStatus) === null || _a === void 0 ? void 0 : _a.syncedBlocks;
        },
        pendingBlocks: function () {
            var _a;
            return (_a = this.serviceStatus) === null || _a === void 0 ? void 0 : _a.pendingBlocks;
        },
        blockID: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.recentBlock.id;
        },
        checksum: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.recentBlock.checksum;
        },
        fileHash: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.recentBlock.file_hash;
        },
        previousHash: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.recentBlock.previous_hash;
        },
        lastSyncedBlockDigitalSignature: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.lastRegHash;
        },
        elapsed: function () {
            var DAY = 86400000;
            // date in local timezone
            var dateNow = new Date();
            // UTC coming midnight
            dateNow.setUTCHours(24, 0, 0, 0);
            // UTC coming midnight to msec
            var midnight = dateNow.getTime() + dateNow.getTimezoneOffset() * 60 * 1000;
            var utcMidnight = Number(new Date(midnight));
            //      = number of milliseconds between current UTC time and midnight of January 1, 1970
            var tmLoc = new Date();
            //The offset is in minutes -- convert it to ms
            var currTime = tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
            var diff = utcMidnight - currTime;
            var progress = (diff / DAY) * 100;
            var progressPerCent = Number((Math.round(progress * 100) / 100).toFixed(2));
            var res = 100 - progressPerCent;
            return res;
        }
    },
    methods: {
        /**
         * Calculates remaining time for the chain sync
         * @returns string
         */
        calcNextSync: function () {
            var currTimeUTC = Number(new Date(this.getCurrentTimeUTC()));
            var midnightUTC = this.getUTCMidnight();
            var diff = Number(midnightUTC - currTimeUTC);
            return this.msToTime(diff);
        },
        /**
         * Gets the UTC midgnitime in msec
         * @returns number
         */
        getUTCMidnight: function () {
            // date in local timezone
            var dateNow = new Date();
            // UTC coming midnight
            dateNow.setUTCHours(24, 0, 0, 0);
            // UTC coming midnight to msec
            var midnight = dateNow.getTime() + dateNow.getTimezoneOffset() * 60 * 1000;
            return Number(new Date(midnight));
        },
        /**
         * Gets the UTC time
         * @returns string
         */
        getCurrentTimeUTC: function () {
            //RETURN:
            //      = number of milliseconds between current UTC time and midnight of January 1, 1970
            var tmLoc = new Date();
            //The offset is in minutes -- convert it to ms
            return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
        },
        /**
         * Formats miliseconds to hh:mm:ss
         * @returns string
         */
        msToTime: function (s) {
            // Pad to 2 or 3 digits, default is 2
            function pad(n, z) {
                z = z || 2;
                return ("00" + n).slice(-z);
            }
            var ms = s % 1000;
            s = (s - ms) / 1000;
            var secs = s % 60;
            s = (s - secs) / 60;
            var mins = s % 60;
            var hrs = (s - mins) / 60;
            return pad(hrs) + " hours and " + pad(mins) + " minutes";
        },
        /**
         * Formats number with leading hashtag and zeros
         * @returns string
         */
        pad: function (num, size) {
            //fixed size
            var SIZE = size ? size : 9;
            // num = num.toString();
            num = "" + num;
            while (num.length < SIZE) {
                num = "0" + num;
            }
            return "#" + num;
        }
    }
});
