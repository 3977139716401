<template>
  <!--begin::Status-->
  <!--begin::Sync Status-->
  <div class="row">
    <div class="col-xxl-8 mb-5 mb-xl-10">
      <SyncStatus
        widget-classes="card-xxl-stretch mb-5 mb-xl-10"
        :serviceStatus="serviceStatus"
        :blockData="blockData"
        :status="status"
      ></SyncStatus>
    </div>
    <!--end::Latest Sync Status-->
    <!--begin::Node Info-->
    <div class="col-xxl-4 flex-column">
      <NodeInfo widget-classes="card-xxl-stretch mb-5 mb-xl-10"></NodeInfo>
    </div>
    <!--end::Node Info-->
  </div>
  <!--end::Sync Status-->
  <!--end::Status-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import SyncStatus from "./SyncStatus.vue";
import NodeInfo from "./NodeInfo.vue";

export default defineComponent({
  name: "status",
  components: {
    NodeInfo,
    SyncStatus
  },
  data() {
    return {
      serviceStatus: {
        pendingBlocks: 0,
        syncedBlocks: 0
      },
      blockData: {
        recentBlock: {},
        lastRegHash: ""
      }
    };
  },
  setup() {
    const store = useStore();
    store.dispatch("fetchInsights");
    store.dispatch("fetchPageData", 1);
    store.dispatch("fetchServiceStatus");
    if (store.getters.latestBlocks.length === 0) {
      store.dispatch("fetchInsights");
    }
    onMounted(() => {
      setCurrentPageTitle("Status");
    });
  },
  created() {
    const store = useStore();
    const delay = 500;

    setTimeout(() => {
      this.serviceStatus = store.getters.serviceStatusInfo;
      const pendingBlocks = store.getters.pendingBlocks;
      this.serviceStatus.pendingBlocks = pendingBlocks;

      const insights = store.getters.latestBlocks;
      const pagedBlocks = store.getters.allBlocksData;

      const mostRecentBlock = pagedBlocks.data[0];
      this.blockData.recentBlock = mostRecentBlock;

      // last registed item
      let registeredItemFileHash = "";
      for (const i in insights) {
        const item = insights[i];
        if (item.file_hash != null) {
          registeredItemFileHash = item.file_hash;
          break;
        }
      }

      this.blockData.lastRegHash = registeredItemFileHash;
    }, delay);
  },
  computed: {
    status() {
      const store = useStore();
      return store.getters.isOnline;
    }
  }
});
</script>
